import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TabsPageGuard implements CanActivate, CanDeactivate<any> {
  private router = inject(Router);

  private readonly notFoundErrorPagesPath = '/error/not-found';
  private savedPath: { [tabName: string]: string } = {};

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean {
    if (this.is404Redirect(nextState?.url)) {
      // 404リダイレクトのときは対象のページが404になった事を示すので、savePathせず遷移させる
      return true;
    }
    const { tabName, path } = this.parseUrl(this.router.url);
    this.savePath(tabName, path);
    return true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean {
    const { tabName, path } = this.parseUrl(state.url);
    if (path === '') {
      // タブトップへの遷移時だけ、保存していたpathを復元する
      const restoredPath = this.restorePath(tabName);
      return restoredPath ? this.router.parseUrl(this.buildUrl(tabName, restoredPath)) : true;
    } else {
      // それ以外は保存されたpathを破棄してそのまま遷移
      this.discardPath(tabName);
      return true;
    }
  }

  parseUrl(url: string): { tabName: string; path: string } {
    const paths = url.split('/');
    const tabName = paths.splice(0, 2).join('');
    const path = paths.join('/');
    return { tabName, path };
  }

  buildUrl(tabName: string, path: string): string {
    return path ? `/${tabName}/${path}` : `/${tabName}`;
  }

  private savePath(tabName: string, path: string) {
    this.savedPath[tabName] = path;
  }

  private discardPath(tabName: string) {
    this.savedPath[tabName] = null;
  }

  private restorePath(tabName: string): string {
    const path = this.savedPath[tabName] ?? '';
    this.discardPath(tabName);
    return path;
  }

  private is404Redirect(nextUrl: string): boolean {
    return nextUrl === this.notFoundErrorPagesPath;
  }
}
